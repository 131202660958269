import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Blog from "../components/blog"
import Login from "../components/login"

const IndexPage = () => (
  <Layout>
    <SEO title="Inicio" />
    <Blog />
    <Login/>
  </Layout>
)

export default IndexPage
