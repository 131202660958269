import React from "react"
import "../css/style.css"
import Odoo from 'odoo-xmlrpc'

var odoo = new Odoo({
    url: "https://mucho-fucho.odoo.com",
    port: 80,
    db: "mucho-fucho",
    username: 'danielsr@aguilarysantiago.com.mx',
    password: "Servicio1357."
});

odoo.connect(function (err) {
    if (err) { return console.log(err); }
    console.log('Connected to Odoo server.');    
});

odoo.connect(function (err) {
    if (err) { return console.log(err); }
    console.log('Connected to Odoo server.');
    var inParams = [];
    inParams.push([]);
    inParams.push(['name', 'x_author', 'x_authorType']); //fields
    inParams.push(0); //offset
    inParams.push(5); //limit
    var params = [];
    params.push(inParams);
    odoo.execute_kw('blog.post', 'search_read', params, function (err, value) {
        if (err) { return console.log(err); }
        console.log('Result: ', value);
    }
    );
});

export default function Login({value}) {
  return (
    <section>
        <input type="submit" onClick={Odoo} placeholder="Peticion" />
  <p>{value}</p>
    </section>
  )
}